"use client";
import Link from "next/link";

export default function NotFound() {
  return (
    <div style={{ padding: "30vh 0px", textAlign: "center" }}>
      <h2>404 | Not Found</h2>
      <p>Could not find requested resource</p>
      <Link href="/" className="btn btn-primary p-2 pr-5 pl-5">
        হোম
      </Link>
    </div>
  );
}
